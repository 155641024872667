const productMenu = [{
    "title":"重症生命支持",
    "url": "/icu"
}, {
    "title":"心胸外科手术",
    "url": "/cardiac"
}];

const jobList = [
    {
        "jobid": "133600505",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/133600505.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "医疗器械注册主管",
        "updatedate": "2023-02-28 17:20:42"
    },
    {
        "jobid": "142577417",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/142577417.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "机械工程师主管",
        "updatedate": "2023-02-28 17:20:22"
    },
    {
        "jobid": "142600377",
        "job_href": "https://jobs.51job.com/beijing-cyq/142600377.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "注册主管",
        "updatedate": "2023-02-28 17:20:20"
    },
    {
        "jobid": "142448727",
        "job_href": "https://jobs.51job.com/yancheng-fnx/142448727.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "办公室主任",
        "updatedate": "2023-02-22 09:13:58"
    },
    {
        "jobid": "123268499",
        "job_href": "https://jobs.51job.com/shanghai-pdxq/123268499.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "嵌入式工程师",
        "updatedate": "2023-02-28 17:20:51"
    },
    {
        "jobid": "136857382",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/136857382.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "产品专员",
        "updatedate": "2023-02-28 17:20:30"
    },
    {
        "jobid": "122807938",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/122807938.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "电子工程师",
        "updatedate": "2023-02-28 17:20:57"
    },
    {
        "jobid": "122824159",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/122824159.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "机械工程师",
        "updatedate": "2023-02-28 17:20:55"
    },
    {
        "jobid": "115771818",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/115771818.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "产品经理",
        "updatedate": "2023-02-28 17:20:59"
    },
    {
        "jobid": "123096439",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/123096439.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "结构工程师",
        "updatedate": "2023-02-28 17:20:53"
    },
    {
        "jobid": "130982890",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/130982890.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "资深电子工程师",
        "updatedate": "2023-02-28 17:20:45"
    },
    {
        "jobid": "136858192",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/136858192.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "注册工程师",
        "updatedate": "2023-02-28 17:20:28"
    },
    {
        "jobid": "142448683",
        "job_href": "https://jobs.51job.com/yancheng-fnx/142448683.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "作业员",
        "updatedate": "2023-02-22 09:13:58"
    },
    {
        "jobid": "142448623",
        "job_href": "https://jobs.51job.com/yancheng-fnx/142448623.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "现场技术员",
        "updatedate": "2023-02-22 09:13:58"
    },
    {
        "jobid": "136041223",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/136041223.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "作业员",
        "updatedate": "2023-02-22 09:13:58"
    },
    {
        "jobid": "142805150",
        "job_href": "https://jobs.51job.com/yancheng-fnx/142805150.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "行政助理",
        "updatedate": "2023-02-22 09:13:52"
    },
    {
        "jobid": "123343403",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/123343403.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "质量体系工程师",
        "updatedate": "2023-02-28 17:20:48"
    },
    {
        "jobid": "136855452",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/136855452.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "检验员",
        "updatedate": "2023-02-28 17:20:32"
    },
    {
        "jobid": "143164379",
        "job_href": "https://jobs.51job.com/shanghai-pdxq/143164379.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "机械工程师",
        "updatedate": "2023-02-28 17:20:16"
    },
    {
        "jobid": "143648674",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/143648674.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "人事专员/招聘专员",
        "updatedate": "2023-02-28 17:20:10"
    },
    {
        "jobid": "145832511",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145832511.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "销售总监",
        "updatedate": "2023-02-28 17:20:05"
    },
    {
        "jobid": "145832914",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145832914.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "区域销售经理",
        "updatedate": "2023-02-28 17:20:03"
    },
    {
        "jobid": "145833077",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145833077.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "市场部经理",
        "updatedate": "2023-02-28 17:20:01"
    },
    {
        "jobid": "145834334",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145834334.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "生产文员",
        "updatedate": "2023-02-28 17:19:58"
    },
    {
        "jobid": "145993033",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145993033.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "售后服务工程师",
        "updatedate": "2023-02-28 17:19:56"
    },
    {
        "jobid": "141560440",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/141560440.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "综合维修工",
        "updatedate": "2023-02-22 09:13:58"
    },
    {
        "jobid": "145118839",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145118839.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "微生物检验员",
        "updatedate": "2023-02-22 09:13:52"
    },
    {
        "jobid": "144772268",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/144772268.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "产品推广专员",
        "updatedate": "2023-02-22 09:13:52"
    },
    {
        "jobid": "146158222",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/146158222.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "人事专员/招聘专员",
        "updatedate": "2023-02-22 09:13:46"
    },
    {
        "jobid": "145863671",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145863671.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "机械工程师（耗材方向）",
        "updatedate": "2023-02-22 09:13:46"
    },
    {
        "jobid": "145773955",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145773955.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "销售总监",
        "updatedate": "2023-02-22 09:13:46"
    },
    {
        "jobid": "145773899",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145773899.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "区域销售经理",
        "updatedate": "2023-02-22 09:13:46"
    },
    {
        "jobid": "145773797",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145773797.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "市场部经理",
        "updatedate": "2023-02-22 09:13:46"
    },
    {
        "jobid": "146434122",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/146434122.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "销售助理文员",
        "updatedate": "2023-03-01 04:00:01"
    },
    {
        "jobid": "136085605",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/136085605.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "电子工程师 (实习生）",
        "updatedate": "2023-02-28 17:20:39"
    },
    {
        "jobid": "136855193",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/136855193.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "产品专员（实习生）",
        "updatedate": "2023-02-28 17:20:37"
    },
    {
        "jobid": "143149725",
        "job_href": "https://jobs.51job.com/beijing-cyq/143149725.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "产品专员",
        "updatedate": "2023-02-28 17:20:16"
    },
    {
        "jobid": "144006765",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/144006765.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "机械工程师（实习生）",
        "updatedate": "2023-02-28 17:20:07"
    },
    {
        "jobid": "145523495",
        "job_href": "https://jobs.51job.com/suzhou-gyyq/145523495.html?s=gsxq_zwlb_gsxqlb&t=17",
        "job_name": "检验员（实习生）",
        "updatedate": "2023-02-22 09:13:52"
    }
];

const productList = {
    "lifesupport":[{
        title:"OASSIST® 体外心肺支持辅助设备",
        imgSrc: require('@/assets/products/ecmo-w512.png'),
        regNo:"国械准注",
        summary:"便携式ECMO系统",
        href:"/ecmo",
        isComing:false
    },{
        title:"STM CP-24 I型 离心泵泵头",
        imgSrc:require('@/assets/products/pump-w512.png'),
        regNo:"国械准注 20233100214",
        summary:"与OASSIST ECMO系统配套使用",
        href:"/stm-cp-24",
        isComing:false
    },{
        title:"STM HC 01 热交换水箱",
        imgSrc:require('@/assets/products/hcsystem-w512.png'),
        regNo:"苏械注准20242101220",
        summary:"用于体外循环心脏支持手术中或术后对患者进行升温或降温调节",
        href:"/hcsystem",
        isComing:false
    },{
        title:"OX-LT系列长效氧合器套包",
        imgSrc:require('@/assets/products/ox-lt-w512-masked.png'),
        isComing:true
    },{
        title:"动静脉插管",
        imgSrc:require('@/assets/products/pipe-w512-masked.png'),
        isComing:true
    }],
    "heartlung":[{
        title:"STM FM 01 医用流量计",
        imgSrc:require('@/assets/products/flowmonitor-w512.png'),
        regNo:"苏械注准 20232101406",
        summary:"用于体外循环手术中循环管道内血液流量的测量",
        href:"/fm01",
        isComing:false
    },{
        title:"STM HLM心肺机",
        imgSrc:require('@/assets/products/hlm-w512-masked.png'),
        isComing:true
    },{
        title:"OX系列氧合器",
        imgSrc:require('@/assets/products/ox-st-w512-masked.png'),
        isComing:true
    }]
}
exports.productMenu = productMenu;
exports.jobList = jobList;
exports.productList = productList;