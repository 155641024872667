<template>
    <div class="intellectual-property">
      <h1 class="py-3 text-left" style="color:rgb(2, 65, 139);">自主知识产权</h1>
        <b-row>
            <b-col v-for="ele in dataList" :key="ele.title" class="p-1" lg="3">
                <b-card>
                    <!-- 上半部分 -->
                    <div class="card-text">
                        <!-- 左侧文字 -->
                        <div class="left-text">
                        <span class="ip-count">{{ ele.count }}+</span>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right-text">
                        <p class="ip-title m-0">{{ ele.title }}</p>
                        </div>
                    </div>
                    
                    <!-- 下半部分（图片） -->
                    <div class="image-container">
                      <b-img :src="ele.imgSrc" :alt="ele.title" class="card-img-bottom"/>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
  </template>
  
  <script>
  export default {
  name: "IntellectualProperty",
    data:function () {
      return {
        dataList:[
            {'count':31, 'title':"发明专利", "imgSrc":require("@/assets/ip/inventings.png")},
            {'count':23, 'title':"PCT", "imgSrc":require("@/assets/ip/pct.png")},
            {'count':21, 'title':"实用性专利", "imgSrc":require("@/assets/ip/properties.png")},
            {'count':32, 'title':"软件著作权", "imgSrc":require("@/assets/ip/software.png")}
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .image-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none; /* 避免遮罩捕获鼠标事件 */
  }
  .card-text {
  display: flex;
  justify-content: space-between;
  background-color: #248DBB;
  padding: 10px;
}

.card .card-body{
  padding:0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



.ip-count {
      display: inline-block;
      width: 2.2em; /* 圆形的宽度和高度，可以根据需要调整 */
      height: 2.2em;
      background-color: #083F83; /* 圆形的底色为红色 */
      border-radius: 50%; /* 使元素变成圆形 */
      text-align: center; /* 文字居中 */
      line-height: 2.2em; /* 文字垂直居中 */
      color: white; /* 字体颜色为白色 */
      font-size:24px;
      font-weight: bold;
    }

.left-text {
  flex: 1; /* 左侧文字占据剩余空间 */
  padding: 0; /* 可根据需要调整间距 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.right-text {
  flex: 3; /* 右侧文字占据剩余空间 */
  padding: 0; /* 可根据需要调整间距 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  min-height: 5em;
}
.ip-title{
  color: white;
  font-size:24px;
  padding:5px;
  text-align: left;
}
.card-img-bottom {
  width: 100%;
  height: auto;
}
  @media (min-width: 1200px) {
    .intellectual-property{
      width:80%;
      margin:0 auto;
      padding:2rem 0;
    }
  }
  </style>