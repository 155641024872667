<template>
    <div class="history">
        <div class="container">
            <div class="page-header my-4 text-left">
                <h1 id="timeline" style="color:rgb(2, 65, 139);">荣誉资质</h1>
            </div>
            <b-row v-for="grpVal in dataListGroup" :key="grpVal.year" class="timeline-group">
              <b-col cols="4" class="d-flex">
                <span class="history-group-year-value">{{ grpVal.year }}年</span>
              </b-col>
              <b-col>
                <ul cols="8" class="p-0 m-0">
                  <li v-for="txt in grpVal.contents" :key="txt" class="text-left">
                    <span class="history-group-content" v-html="txt"></span>
                  </li>
                </ul>
              </b-col>
            </b-row>
        </div>
    </div>
  </template>
  
  <script>
  export default {
  name: "HistoryNew",
    data:function () {
      return {
          dataListGroup:[
                {year:2024, contents:['入选“工信部2024安全应急装备应用推广典型案例”']},
                {year:2023, contents:["在习总书记“江苏考察”中进行了汇报", "入选“优秀国产医疗设备目录”", "江苏省首台（套）重大装备认定", "OASSIST<sup>®</sup> 体外心肺支持辅助设备与离心泵泵头成功注册上市"]},
                {year:2022, contents:['作为园区生物医药成果代表陈列于苏州革命博物馆', "中国“潜在独角兽企业”"]},
                {year:2021, contents:["苏州市“独角兽”培育企业"]},
                {year:2020, contents:["参与中央应急物资保障体系建设", "江苏省“双创人才”"]},
                {year:2019, contents:["姑苏创新创业领军人才计划（创业类）", "苏州工业园区科技领军人才", "承担江苏省重点研发计划（省科技厅）"]}
            ]
      }
    }
  }
  </script>
  
  <style scoped>
.history-year, .history-content{
  font-size:18px;
  color:#504366;
}

.history-group-year-value{
  font-size: 24px;
  font-weight:bold;
  color:#248DBB;
}

.history-group-content{
  color:#504366;
}
/* .history-content{
  color:#504366;
} */

.timeline-group{
  background-color:#F2F2F2;
  margin:10px auto;
  padding:1rem;

}

@media (min-width: 1200px) {
    .history{
      width:80%;
      margin:0 auto;
      padding:2rem 0;
    }

    .history .timeline-plain, .history .timeline-group{
      margin:0.5rem 7%;

    }
  }
  </style>