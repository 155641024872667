<template>
<div class="top-nav">
  <div class="container">
    <b-navbar toggleable="lg" type="light" variant="custom">
      <b-navbar-brand to="/">
        <b-img class="mainProps" :src="require('@/assets/logo-blue.jpeg')"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/about">关于赛腾</b-nav-item>
          <b-nav-item-dropdown text="产品中心" right>
            <b-dropdown-item :to="item.url" v-for="item in productMenu" :key="item.url">{{item.title}}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/recruiting">招贤纳士</b-nav-item>
          <b-nav-item to="/contact">联系我们</b-nav-item>
          <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="搜索"></b-form-input>
            <b-button size="sm" class="search-button my-2 my-sm-0" type="submit" disabled>搜索</b-button>
          </b-nav-form>
          <b-nav-item-dropdown text="切换语言" right>
          <b-dropdown-item href="https://www.st-med.com">中文</b-dropdown-item>
          <b-dropdown-item href="https://en.st-med.com">English</b-dropdown-item>
        </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</div>
</template>

<script>
import $ from "jquery";
import { productMenu } from "@/constant/index";
export default {
  name: "TopNav",
  components: {},
  data:function (){
    return {
      logo:{
        imgSrc:'logo-white.png'
      },
      leftLinks:[],
      productMenu:productMenu
    }
  },mounted() {
    $('li.dropdown').mouseover(function() {
      $(this).click();
    });
    //
    // $('ul.dropdown-menu').mouseout(function(){
    //   $(this).removeClass("show");
    // })
  }
}
</script>

<style>
.top-nav{
  width:100%;
  border-bottom:2px solid rgb(2, 65, 139);
}

.top-nav .nav-item a{
  letter-spacing: 0.1rem;
  margin: 0 0.2rem;
  color:rgb(2, 65, 139)!important;
}

.top-nav .bg-custom{
  background-color: transparent;
}

.top-nav .search-button{
  background-color: rgb(2, 65, 139);
  border-color: rgb(2, 65, 139);
}

@media (max-width: 1023px){
  .mainProps{
    width:100px;
  }
}

@media (min-width: 1024px){
  .mainProps{
    width:200px;
  }
}
</style>